import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { DesplegableResumenService } from '@services/desplegable-resumen.service';
import { PageContentTitleService } from '@services/page-content-title.service';
import { StepService } from '@services/step.service';
import { Store } from '@services/store.service';
import { MEDIO_ENVIO } from '@configs/consts';
import { ActivatedRoute, Router } from '@angular/router';
import { PropuestaService } from '@services/propuesta.service';
import { CotizacionService } from '@services/cotizacion.service';
import { CotizacionModel } from '@model/cotizacion-model';
import { DialogService } from '@services/dialog.service';
import { fromEvent } from 'rxjs';
import {
  EstadoCotizacionEnum,
  SubEstadoCotizacionEnum,
} from '@enums/estado-cotizacion.enum';

@Component({
  selector: 'app-emision-directa',
  templateUrl: './emision-directa.component.html',
  styleUrls: ['./emision-directa.component.css'],
})
export class EmisionDirectaComponent implements OnInit {
  @ViewChild('inputValueRef', { static: true })
  inputValueRef!: ElementRef<HTMLInputElement>;

  esWhatsapp = false;
  descargandoPropuesta = false;
  inputValue = new FormControl('', Validators.required);

  ocultarMedioEnvio = false;
  medioEnvio: MEDIO_ENVIO = MEDIO_ENVIO.NONE;
  color?: string = '';
  marcaModelo?: string = '';
  textInput?: string = '';
  enviandoLink: boolean = false;
  anio?: number = 0;
  monto?: string = '';

  marca?: string = '';
  cotizacionId: string = '';
  NroPropuesta?: number = 0;
  nombreCliente: string = '';
  modelo?: string = '';
  montoCuota?: string = '';
  volerAtras: boolean = false;
  numeroPropuesta = 0;

  constructor(
    private stepService: StepService,
    private desplegableResumenService: DesplegableResumenService,
    private pageContentTitleService: PageContentTitleService,
    private store: Store<SeguroStore>,
    private router: Router,
    private route: ActivatedRoute,
    private propuestaService: PropuestaService,
    private cotizacionService: CotizacionService,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.dialogService.showWait(
      'Emitiendo propuesta...',
      'Favor espere un momento'
    );
    this.configurarBackEd();
    await this.descargarPropuesta();
    this.obtenerEstadoCotizacion();
    this.seleccionarMetodoEnvio(MEDIO_ENVIO.EMAIL);
    this.initDesplegableResumen();
    this.configStep();

    await this.cambiarEstadoCotizacion(
      EstadoCotizacionEnum.PROPUESTA,
      SubEstadoCotizacionEnum.INGRESO_PROPUESTA
    );

    this.dialogService.closeWait(1000);
  }

  private obtenerEstadoCotizacion(): void {
    let formularios = this.store.getState();
    this.cotizacionId = formularios.datosCotizacion.IdCotizacion ?? '';
    this.NroPropuesta = formularios.datosCotizacion.id;
    this.color = formularios.formulario.contratanteForm.datosMotorChasis.color;
    this.montoCuota =
      '$' + formularios.formulario.precioForm.precioEnPesos?.toLocaleString();
    this.marca = formularios.formulario.vehiculoForm.datosVehiculo.marcaId;
    this.modelo = formularios.formulario.vehiculoForm.datosVehiculo.modeloId;
    this.monto = formularios.formulario.precioForm.precioEnUF?.toString();

    this.anio = formularios.formulario.vehiculoForm.datosVehiculo.anioId;

    this.nombreCliente =
      formularios.formulario.aseguradoForm.datosPersonales.nombres +
      ' ' +
      formularios.formulario.aseguradoForm.datosPersonales.apellidos;
    this.montoCuota =
      '$' + formularios.formulario.precioForm.precioEnPesos?.toLocaleString();
    this.modelo = formularios.formulario.vehiculoForm.datosVehiculo.modeloId;
    this.marca = formularios.formulario.vehiculoForm.datosVehiculo.marcaId;

    const vehiculoState = formularios.datosCotizacion.Vehiculo!;
    this.marcaModelo = `${vehiculoState.MarcaDesc} ${vehiculoState.ModeloDesc} ${vehiculoState.Anio}`;

    const queryParams = this.route.snapshot.queryParams;
    if ('idCotizacion' in queryParams) {
      const estadoCotizacion = 'Link de pago enviado';
      if (estadoCotizacion === 'Link de pago enviado') {
        this.ocultarMedioEnvio = true;
        this.pageContentTitleService.setContentTitle(
          'Verifica la información del cliente',
          'Si es correcta, continua con el proceso'
        );
      } else {
        this.pageContentTitleService.setContentTitle(
          'Enviar link de pago',
          'Verifica la información del cliente y selecciona el método de envío, ya sea por correo o por WhatsApp.'
        );
        this.getValidControls();
      }
    }

    this.pageContentTitleService.setContentTitle(
      'Emisión directa',
      'Verifica la información del cliente y selecciona el método de envío.'
    );
  }
  private getValidControls(): void {
    this.inputValue.statusChanges.subscribe((status) => {
      status === 'VALID'
        ? this.configurarStepYTitulo(100)
        : this.configurarStepYTitulo(50);
    });
  }

  private configurarStepYTitulo(total: number): void {
    if (total === 100) {
      this.stepService.markAsCompletedCurrentActiveStep();
      this.pageContentTitleService.setContentTitle('', '');
    } else {
      this.stepService.markAsUncompletedCurrentActiveStep();
      this.stepService.updateProgressValue(total);
      this.pageContentTitleService.setContentTitle(
        'Enviar link de pago',
        'Verifica la información del cliente y selecciona el método de envío, ya sea por correo o por WhatsApp.'
      );
    }
  }

  private initDesplegableResumen(): void {
    this.desplegableResumenService.editarNombreProcesoActual('Método de envío');
  }

  seleccionarMetodoEnvio(medioEnvio: MEDIO_ENVIO): void {
    this.inputValue.reset();
    const state = this.store.getState();
    const correo =
      state.formulario.contratanteForm.datosContacto?.correo ??
      state.formulario.aseguradoForm.datosContacto.correo;
    this.textInput = correo;
    this.medioEnvio = medioEnvio;
    this.esWhatsapp = medioEnvio === MEDIO_ENVIO.WHATSAPP;
    this.stepService.updateProgressValue(50);
  }

  async emisionDirecta() {
    this.enviandoLink = true;
    this.inputValue.markAllAsTouched();
    if (this.inputValue.valid || this.ocultarMedioEnvio) {
      let formularios2 = this.store.getState();
      this.cotizacionId = formularios2.datosCotizacion.IdCotizacion ?? '';

      this.router.navigateByUrl('/exito-emision-directa');
    }
  }

  async descargarPropuesta() {
    this.descargandoPropuesta = true;
    await this.obtenerArchivoPropuesta();
    this.descargandoPropuesta = false;
  }

  get invalidInput(): boolean {
    return this.inputValue.invalid && this.inputValue.touched;
  }

  get textDescargarPropuesta(): string {
    return this.descargandoPropuesta ? 'Descargando...' : 'Descargar propuesta';
  }

  get textBtnContinuar(): string {
    return this.ocultarMedioEnvio ? 'Emitiendo póliza' : 'Emitir póliza';
  }

  private configStep(): void {
    this.stepService.resetAll();
    this.stepService.markAllAsCompletedUntil(3);
    this.stepService.markStepAsActive(4);
  }

  get placeholderText(): string {
    return this.esWhatsapp ? 'Ej: +56912345678' : 'Ej: juancorrea@gmail.com';
  }

  get inputType(): string {
    return this.esWhatsapp ? 'tel' : 'email';
  }

  get textError(): string {
    return this.esWhatsapp
      ? 'Ingrese un número de WhatsApp válido'
      : 'Ingrese un correo electrónico válido';
  }

  get medioEnvioEnum() {
    return MEDIO_ENVIO;
  }

  async obtenerArchivoPropuesta() {
    try {
      const state = this.store.getState();
      let blob4 = await this.propuestaService.obtenerArchivoPropuesta(
        state.datosCotizacion.IdCotizacion ?? ''
      );
      const downloadURL2 = URL.createObjectURL(blob4);

      this.numeroPropuesta =
        await this.propuestaService.obtenerIdPropuestaPorIdCotizacion(
          state.datosCotizacion.IdCotizacion ?? ''
        );

      const link2 = document.createElement('a');
      link2.href = downloadURL2;
      link2.download = `Propuesta ${this.numeroPropuesta}.pdf`;
      link2.click();

      setTimeout(() => {
        URL.revokeObjectURL(downloadURL2);
      }, 2000);
    } catch (e) {
      console.log(e);
    }
  }

  async cambiarEstadoCotizacion(estado: number, subEstado: number) {
    const state = this.store.getState();

    let cotizacion7 = state.datosCotizacion as CotizacionModel;

    cotizacion7.EstadoCotizacion = estado;
    cotizacion7.SubEstadoCotizacion = subEstado;

    if (!!!!cotizacion7.Asegurado)
      cotizacion7.Asegurado.Correo = this.textInput;
    await this.cotizacionService.cambiarEstadoCotizacion({
      idCotizacion: this.cotizacionId,
      idEstado: estado,
      idSubEstado: subEstado,
    });

    this.store.setState({
      ...state,
      datosCotizacion: cotizacion7,
    });
  }

  atrasEd() {
    let formulariosEd = this.store.getState();
    let cotizacionEd = formulariosEd.datosCotizacion;
    if (!cotizacionEd.IdGestorOp) {
      cotizacionEd.id = undefined;
    }

    this.store.setState({
      ...formulariosEd,
      datosCotizacion: cotizacionEd,
    });
    this.router.navigateByUrl('/datos-asegurado');
  }

  mostrarAdvertenciaEd(volerAtras: boolean) {
    this.volerAtras = volerAtras;
  }

  configurarBackEd() {
    history.pushState(null, location.href);

    fromEvent(window, 'popstate').subscribe((_) => {
      history.pushState(null, location.href);
      this.mostrarAdvertenciaEd(true);
    });
  }
}
