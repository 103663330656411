import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { Store } from '@services/store.service';

@Injectable({
  providedIn: 'root',
})
export class LeasingService {

  private isLeasing$ = new BehaviorSubject<boolean>(this.store.getState().datosConvenio?.esLeasing ?? false);

  constructor(private store: Store<SeguroStore>) {}

  activate(value: boolean): void {
    return this.isLeasing$.next(value);
  }

  get status$(): Observable<boolean> {
    return this.isLeasing$.asObservable();
  }

  get isLeasing(): boolean {
    return this.isLeasing$.getValue();
  }
}
