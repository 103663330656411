import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LoginService } from '@services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthResolver implements Resolve<Promise<any>> {
  constructor(private loginService: LoginService) {}

  resolve(): Promise<any> {
    return this.loginService.obtenerTokenResolver();
  }
}