import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { CotizacionModel, PersonaModel } from '@model/cotizacion-model';
import { ConvenioService } from '@services/convenio.service';
import { Store } from '@services/store.service';
import { desencriptarBase64_16 } from '@utils/encript.util';
import { FormularioNuevo } from '@mocks/formulario';
import {
  TipoRelacionPersonaEnums,
} from '@enums/estado-cotizacion.enum';
import { format } from '@utils/rut.util';
import { environment } from '@environments/environment';
import { LeasingService } from '@services/leasing.service';
import { switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ParamsUrl } from '@interfaces/params-url.interface';

@Component({
  selector: 'app-inicio-cotizador',
  templateUrl: './inicio-cotizador.component.html',
  styleUrls: ['./inicio-cotizador.component.css'],
})
export class InicioCotizadorComponent implements OnInit {
  puntitos: string = '.';

  constructor(
    private route: ActivatedRoute,
    private store: Store<SeguroStore>,
    private router: Router,
    private leasing: LeasingService
  ) {}

  ngOnInit() {
    setInterval(() => this.crearPuntitos(), 1000);
    const currentURL = window.location.href;

    this.obtenerParametrosUrl()
      .subscribe({
        next: (params) => {
          const { datosConvenio } = this.store.getState();
          const ejecutivoId = 0;
          let cotizacion = new CotizacionModel();
          cotizacion.ConvenioId = parseInt(params.convenioId);
          cotizacion.Sucursal = datosConvenio.sucursal;
          cotizacion.EjecutivoId = ejecutivoId;
          cotizacion.EstadoCotizacion = 0;

          cotizacion.Ejecutivo = new PersonaModel();
          cotizacion.Ejecutivo.Rut = params.rutEjecutivo;
          cotizacion.Ejecutivo.Dv = params.rutEjecutivoDv;
          cotizacion.Ejecutivo.Nombres = params.nombreEjecutivo;
          cotizacion.Ejecutivo.TipoRelacion = TipoRelacionPersonaEnums.EJECUTIVO;
          cotizacion.IdApp = environment.IdApp;
          cotizacion.DiasVigencia = datosConvenio.diasVigencia;
          cotizacion.tieneProductosVC = datosConvenio.tieneProductosVC;
          cotizacion.tieneProductosVP = datosConvenio.tieneProductosVP;

          FormularioNuevo.aseguradoForm.datosPersonales.rut = format(
            params.rutAsegurado.replace('-', ''),
            { dots: true }
          );
          FormularioNuevo.vehiculoForm.datosVehiculo.patente = params.patente;

          if (params.idGestorOp) {
            FormularioNuevo.aseguradoForm.datosContacto.correo = params.email;
            FormularioNuevo.aseguradoForm.datosContacto.telefono =
              params.telefono.substr(2);
            FormularioNuevo.aseguradoForm.datosContacto.celular =
              params.telefono.substr(2);

            cotizacion.IdGestorOp = params.idGestorOp;
          }
          if (this.leasing.isLeasing) FormularioNuevo.aseguradoForm.nroPolizaLeasing = '';

          if (datosConvenio.enableRecaptchaEnterprise) {
            let script: HTMLScriptElement | null = document.querySelector(
              'script[src*="https://www.google.com/recaptcha/enterprise.js"]'
            );
            if (!script) {
              script = document.createElement('script');
              script.src =
                'https://www.google.com/recaptcha/enterprise.js?render=' +
                datosConvenio.reCaptchaEnterprisePublicKey;
              script.async = false;
              document.head.appendChild(script);
            }
          }

          this.store.setState({
            ...this.store.getState(),
            datosConvenio: datosConvenio,
            datosCotizacion: cotizacion,
            formulario: FormularioNuevo,
            urlBase: currentURL,
            continuaCotizacion: false,
            googleTagManagerHead: datosConvenio.tagManagerHead ?? '',
            googleTagManagerBody: datosConvenio.tagManagerBody ?? '',
            mostrarMandato: datosConvenio.mostrarMandato ?? false,
          });

          setTimeout(() => this.router.navigateByUrl('/datos-asegurado'), 1000);
        },
        error: error => console.error(error),
      });
  }

  private obtenerParametrosUrl(): Observable<ParamsUrl> {
    let paramsUrl: ParamsUrl = {
      nombreEjecutivo: '',
      rutEjecutivo: '',
      rutEjecutivoDv: '',
      rutAsegurado: '',
      rutAseguradoDv: '',
      patente: '',
      convenioId: '',
      email: '',
      telefono: '',
      idGestorOp: '',
    };
    return this.route.queryParams.pipe(
      switchMap((params) => {
        if (params.go) {
          paramsUrl.convenioId = desencriptarBase64_16(params.z);
          paramsUrl.nombreEjecutivo = desencriptarBase64_16(params.ne);
          paramsUrl.rutEjecutivo = desencriptarBase64_16(params.re);
          paramsUrl.rutEjecutivoDv = desencriptarBase64_16(params.rd);
          paramsUrl.rutAsegurado = desencriptarBase64_16(params.r);
          paramsUrl.rutAseguradoDv = desencriptarBase64_16(params.d);
          paramsUrl.patente = desencriptarBase64_16(params.p);
        } else {
          paramsUrl.rutAsegurado = desencriptarBase64_16(params.r);
          paramsUrl.rutAseguradoDv = desencriptarBase64_16(params.d);
          paramsUrl.patente = desencriptarBase64_16(params.p);
          paramsUrl.idGestorOp = desencriptarBase64_16(params.i);
          paramsUrl.telefono = '';
          paramsUrl.email = '';
          paramsUrl.convenioId = desencriptarBase64_16(params.z);
          paramsUrl.nombreEjecutivo = desencriptarBase64_16(params.ne);
          paramsUrl.rutEjecutivo = desencriptarBase64_16(params.re);
          paramsUrl.rutEjecutivoDv = desencriptarBase64_16(params.rd);
        }
        return of(paramsUrl);
      }
    ));
  }

  crearPuntitos() {
    this.puntitos = this.puntitos + '.';

    if (this.puntitos.length > 4) this.puntitos = '';
  }
}
