import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerResponse } from '@interfaces/server-response.interface';

@Injectable({
  providedIn: 'root',
})
export class IntencionService {
  constructor(private httpClient: HttpClient) {}

  enviarLink(cotizacionId: string, correo: string) {
    return this.httpClient.post<ServerResponse>('/intencion/EnviarLink', {
      parametro: cotizacionId.toString(),
      correo,
    });
  }

  enviarWhatsApp(cotizacionId: string, numeroTelefono: string) {
    return this.httpClient.post<ServerResponse>('/intencion/EnviarWhatsApp', {
      parametro: cotizacionId.toString(),
      numeroTelefono,
    });
  }

  async obtenerUrlIntencion(cotizacionId: string): Promise<any> {
    return this.httpClient
      .post<any>('/intencion/GenerarIntencion', {
        parametro: cotizacionId.toString(),
      })
      .toPromise();
  }
}
