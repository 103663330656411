import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@services/dialog.service';
import { PolizaService } from '@services/poliza.service';
import { tieneValor } from '@utils/boolean.util';
import { encriptarParametrosUrl } from '@utils/encript.util';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-exito-transaccion',
  templateUrl: './exito-transaccion.component.html',
  styleUrls: ['./exito-transaccion.component.css']
})
export class ExitoTransaccionComponent implements OnInit {

  datosPoliza: any;
  descargandoPoliza = false;
  descargandoComprobante = false;
  puntitos: string = ".";
  cargandoPoliza: boolean = true;
  cargando: boolean = true;


  constructor(
    private polizaService: PolizaService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private navigationService: NavigationService,
  ) { }

  async ngOnInit() {
    this.dialogService.showWait("Cargando datos...", "Favor espere un momento");

    setInterval(() => this.crearPuntitos(), 1000);
    await this.sleep(3000);


    let datosEmisionSuscripcion: string = "";
    let datosEmisionDirecta: string = "";

    this.route.queryParams.subscribe(params => {
      datosEmisionSuscripcion = params.p;
      datosEmisionDirecta = params.d;
    });

    this.datosPoliza = tieneValor(datosEmisionSuscripcion) ? await this.polizaService.generarPoliza(datosEmisionSuscripcion) :
      tieneValor(datosEmisionDirecta) ? await this.polizaService.generarDirectaPoliza(datosEmisionDirecta)
        : null;
    this.cargandoPoliza = false;

    this.dialogService.closeWait();
    this.cargando = false;

    if(this.datosPoliza.resultado==false){
      this.navigationService.redirectError();
    }
  }

  async descargarPoliza() {
    this.descargandoPoliza = true;

    try {

      let blob11 = await this.polizaService.obtenerArchivoPoliza(this.datosPoliza.numeroCotizacion);
      const downloadURL11 = URL.createObjectURL(blob11);

      // Crear un enlace temporal y hacer clic para iniciar la descarga
      const link11 = document.createElement('a');
      link11.href = downloadURL11;
      link11.download = "Poliza " + this.datosPoliza.numeroPoliza + '.pdf'; // Nombre de archivo sugerido
      link11.click();

      setTimeout(() => {
        URL.revokeObjectURL(downloadURL11);
      }, 2000);
    } catch (e) {
      console.log(e);
    }



    setTimeout(() => {
      this.descargandoPoliza = false;
    }, 2000);
  }

  redirectError() {
    let url = '/error?t=' + encriptarParametrosUrl("Error") + "&m=" + encriptarParametrosUrl("Intentelo mas tarde");
    this.router.navigateByUrl(url);
  }

  crearPuntitos() {
    this.puntitos = this.puntitos + ".";

    if (this.puntitos.length > 4)
      this.puntitos = "";
  }

  sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }


}
