import { AbstractControl } from "@angular/forms";
import { format, validate } from "@utils/rut.util";

export class RutValidator {
  static validateRut(control: AbstractControl) {
    const value = control.value;

    if (value === null || value == "") {
      return null;
    }
    const rutDesformateado = value.replace(/[\.\-]/g, "").trim();

    if (!/^\d+[0-9Kk]$/.test(rutDesformateado)) {
      return { invalid: "Rut inválido" };
    }

    const numeroRut = parseInt(rutDesformateado.slice(0, -1), 10);

    if (numeroRut <= 1000000 || numeroRut >= 100000000) {
      return {
        invalid: "Rut inválido",
      };
    }

    const rutFormatted = format(control.value, { dots: true });
    return validate(rutFormatted) ? null : { invalid: "Rut inválido" };
  }
}
1;
