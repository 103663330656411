<div class="bs-accordeon" *ngIf="coberturas.length>0">
    <div class="accordeon-item">
        <input type="checkbox" checked name="accordeon-group" id="accordeon-item-10" />
        <label class="accordeon-item-header" for="accordeon-item-10">
            <figure></figure>
            <span>Coberturas Flexibles</span>
        </label>
        <div class="accordeon-item-content acc-cob" style="position: relative; padding:0px">
            <div class="cabecera">
                <div class="columna titulo descriptivo no-border"></div>
                <div class="columna titulo" *ngFor="let plan of planesOriginales" style="max-width: 100%">
                    <h1 class="font-tarifa">{{ plan.name }}</h1>
                </div>
            </div>
            <div class="contenido" style="
                      border-top: 3px solid var(--bs--color-grises-gris-20);
                      position: absolute;
                    "></div>
            <div style="height: 100px" class="contenido" *ngFor="let cobertura of coberturas">
                <div style="height: 100px; display: block" class="columna descriptivo">
                    <p style="
                          white-space: pre-line;
                          text-indent: 0px;
                          padding: 15px;
                          height: 70px;
                          display: grid;
                          place-items: center;
                        ">
                        {{ cobertura.nombreCobertura }}
                    </p>
                </div>
                <div style="height: 100px" *ngFor="let plan of obtenerPlanes(cobertura.planes) " class="columna" [ngClass]="cobertura.planes.length==1 ? 'maxw' : ''">
                    <div *ngIf="plan.opciones.length > 1">
                        <div *ngFor="let op of plan.opciones" style="padding-bottom: 10px;">
                            <a class="bs-btn tabla" [class]="op.seleccionado? 'bs-btn-primary' : 'bs-btn-secondary'" [disableLink]="deshabilitar"
                                (click)="seleccionarOpc(op.id,op.seleccionado, plan.id, op.idGrupo, true, op.tipoCobertura =='OBLIGATORIA')">
                                {{op.texto}} </a>
                        </div>
                    </div>

                    <div *ngIf="plan.opciones.length == 1">
                        <div *ngFor="let op of plan.opciones" style="padding-bottom: 20px;">
                            <p-checkbox [(ngModel)]="op.seleccionado" id="op.id" [binary]="true" [disabled]="deshabilitar"
                                (onChange)="seleccionarOpc(op.id, op.seleccionado, plan.id, null, false)"></p-checkbox>
                        </div>
                    </div>
                    <span *ngIf="plan.opciones.length == 0" style="margin-bottom: 20px;" class="nok"></span>

                </div>


            </div>
        </div>
    </div>
</div>