export enum EstadoCotizacionEnum {
  NUEVO = 0,
  INGRESADA = 1,
  EN_CURSO = 2,
  COTIZACION = 3,
  PROPUESTA = 4,
  SUSCRIBIENDO_PAGO = 5,
  EMITIDA = 6,
  CADUCADA = 7,
}

export enum SubEstadoCotizacionEnum {
  INGRESO_ASEGURADO = 1,
  INGRESO_VEHICULO = 2,
  INGRESO_TARIFAS = 3,
  INGRESO_CONTRATANTE = 4,
  INGRESO_FORMA_PAGO = 5,
  ENVIO_LINK_PAGO = 6,
  GENERACION_POLIZA = 7,
  INGRESO_PROPUESTA = 8,
  INGRESO_PROCESO_INSPECCION = 9,
  EN_EMISION = 10,
  RECHAZO_INSPECCION = 11,
}

export enum TipoArchivoEnum {
  COTIZACION = 6,
  PROPUESTA = 7,
  POLIZA = 11,
  FACTURA = 27,
  CARTA_RESGUARDO_LEASING = 30,
}

export class SistemaEnums {
  public static VENTA_ASISTIDA: number = 1;
}

export class TipoRelacionPersonaEnums {
  public static CONTRATANTE: number = 2;
  public static ASEGURADO: number = 3;
  public static EJECUTIVO: number = 5;
}

export class FormaPagoEnum {
  public static SUSCRIPCION: number = 2;
  public static CONTADO: number = 3;
  public static AVISO: number = 4;
}
