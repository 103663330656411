import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DatosConvenio,
  DatosPin,
  SeguroStore,
} from '@interfaces/seguro-store.interface';
import {
  CotizacionModel,
  FormaPagoModel,
  PersonaModel,
  VehiculoModel,
} from '@model/cotizacion-model';
import { Store } from '@services/store.service';
import { FormularioNuevo } from '@mocks/formulario';
import {
  EstadoCotizacionEnum,
  SubEstadoCotizacionEnum,
  TipoRelacionPersonaEnums,
} from '@enums/estado-cotizacion.enum';
import { format } from '@utils/rut.util';
import { CotizacionService } from '@services/cotizacion.service';
import { tieneValor } from '@utils/boolean.util';
import { DesplegableResumenService } from '@services/desplegable-resumen.service';
import { ComunService } from '@services/comun.service';
import { environment } from '@environments/environment';
import { reemplazarTodo } from '@utils/string.util';
import { ContinuarCotizacion } from '@model/continuar-cotizacion.model';
import { NavigationService } from '@services/navigation.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-inicio-continuar-cotizador',
  templateUrl: './inicio-continuar-cotizador.component.html',
  styleUrls: ['./inicio-continuar-cotizador.component.css'],
})
export class InicioContinuarCotizadorComponent implements OnInit {
  cotizacionId = '';
  nombreEjecutivo: string = '';
  rutEjecutivo: string = '';
  rutEjecutivoDv: string = '';
  convenioId = 0;

  constructor(
    private route: ActivatedRoute,
    private desplegableResumenService: DesplegableResumenService,
    private cotizacionService: CotizacionService,
    private store: Store<SeguroStore>,
    private comunService: ComunService,
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.obtenerDatosCotizacionConvenio$().subscribe({
      next: datosCotizacion => {
        const state = this.store.getState();

        const cotizacion = this.llenarObjetoCotizacion(datosCotizacion, state.datosConvenio);
        const datosPin = new DatosPin();

        if (
          tieneValor(datosCotizacion.nroPin) &&
          tieneValor(datosCotizacion.descuento)
        ) {
          datosPin.descuento = datosCotizacion.descuento;
          datosPin.nroPin = datosCotizacion.nroPin;
        }

        this.setearStoreConDatos(state, state.datosConvenio, cotizacion, datosPin);
        this.initDesplegableResumen();
        this.desplegableResumenService.editarNumeroCotizacion(this.cotizacionId);
        this.agregarResumenDatosGenerales();
        this.agregarAseguradoItemResumen();
        this.agregarVehiculoItemResumen(
          datosCotizacion.marcaDescripcion,
          datosCotizacion.modeloDescripcion,
          datosCotizacion.anio
        );
        this.hacerRedireccionSegunEstado(datosCotizacion, cotizacion, state.datosConvenio);
      },
      error: error => {
        console.error(error);
      }
    })
  }

  private obtenerDatosCotizacionConvenio$(): Observable<ContinuarCotizacion> {
    return this.procesarQueryParams().pipe(
      switchMap(() => {
        return this.cotizacionService.obtenerCotizacion(this.cotizacionId);
      }),
      tap(cotizacion => {
        if (!cotizacion.continuable) {
          this.navigationService.redirectError(cotizacion.mensajeNoContinuable);
        }
      }),
      filter(cotizacion => cotizacion.continuable),
      catchError(error => {
        this.navigationService.redirectError();
        return throwError(error);
      })
    );
  }

  private agregarResumenDatosGenerales(): void {
    const state = this.store.getState();
    const { nombreConvenio, nombreCorredor } = state.datosConvenio;
    const { Nombres } = state.datosCotizacion.Ejecutivo as PersonaModel;

    this.desplegableResumenService.agregarResumen({
      title: 'Datos generales',
      expanded: true,
      items: [
        {
          title: 'Nombre del corredor',
          description: nombreCorredor?.toUpperCase(),
        },
        {
          title: 'Ejecutivo',
          description: Nombres?.toUpperCase(),
        },
        {
          title: 'Convenio',
          description: nombreConvenio?.toUpperCase(),
        },
      ],
    });
  }

  hacerRedireccionSegunEstado(
    datosCotizacion: ContinuarCotizacion,
    cotizacion: CotizacionModel,
    convenio: DatosConvenio
  ) {
    const esPtRc = convenio.tipoFlujo === 'PT+RC';

    const estadoActualCotizacion = datosCotizacion.estadoCotizacion;
    const subEstadoActualCotizacion = datosCotizacion.idSubEstado;
    cotizacion.EstadoCotizacion = estadoActualCotizacion;
    cotizacion.SubEstadoCotizacion = subEstadoActualCotizacion;
    if (
      esPtRc &&
      subEstadoActualCotizacion ==
        SubEstadoCotizacionEnum.INGRESO_PROCESO_INSPECCION
    ) {
      setTimeout(() => this.router.navigateByUrl('/estado-solicitud'), 1000);
    } else if (
      subEstadoActualCotizacion == SubEstadoCotizacionEnum.ENVIO_LINK_PAGO ||
      subEstadoActualCotizacion == SubEstadoCotizacionEnum.INGRESO_PROPUESTA ||
      subEstadoActualCotizacion == SubEstadoCotizacionEnum.INGRESO_CONTRATANTE
    ) {
      setTimeout(() => this.router.navigateByUrl('/link-pago'), 1000);
    } else if (
      subEstadoActualCotizacion == SubEstadoCotizacionEnum.GENERACION_POLIZA
    ) {
      setTimeout(
        () => this.router.navigateByUrl('/exito-emision-directa'),
        1000
      );
    } else {
      cotizacion.EstadoCotizacion = EstadoCotizacionEnum.COTIZACION;
      cotizacion.SubEstadoCotizacion = SubEstadoCotizacionEnum.INGRESO_TARIFAS;
      setTimeout(() => this.router.navigateByUrl('/planes'), 1000);
    }
  }

  setearStoreConDatos(
    state: SeguroStore,
    convenio: DatosConvenio,
    cotizacion: CotizacionModel,
    datosPin: DatosPin
  ) {
    const currentURL = window.location.href;
    this.store.setState({
      ...state,
      datosConvenio: convenio,
      datosCotizacion: cotizacion,
      formulario: FormularioNuevo,
      urlBase: currentURL,
      datosPin: datosPin,
      continuaCotizacion: true,
      googleTagManagerHead: convenio.tagManagerHead ?? '',
      googleTagManagerBody: convenio.tagManagerBody ?? '',
      mostrarMandato: convenio.mostrarMandato ?? false,
      validarChasisSinPatronesRepetidos:
        convenio.validarChasisSinPatronesRepetidos ?? false,
      validarMotorSinPatronesRepetidos:
        convenio.validarMotorSinPatronesRepetidos ?? false,
      productoAdicional: convenio.productoAdicional ?? null,
    });
  }

  llenarObjetoCotizacion(
    datosCotizacion: ContinuarCotizacion,
    convenio: DatosConvenio
  ): CotizacionModel {
    const cotizacion = new CotizacionModel();
    cotizacion.ConvenioId = this.convenioId;
    cotizacion.Sucursal = datosCotizacion.sucursal;
    cotizacion.EjecutivoId = parseInt(this.rutEjecutivo);
    cotizacion.id = datosCotizacion.id;
    cotizacion.IdCotizacion = datosCotizacion.idCotizacion;
    cotizacion.DescuentoCorredor = datosCotizacion.descuentoCorredor;
    cotizacion.EstadoCotizacion = EstadoCotizacionEnum.COTIZACION;
    cotizacion.SubEstadoCotizacion = SubEstadoCotizacionEnum.INGRESO_TARIFAS;
    cotizacion.IdApp = datosCotizacion.idApp;
    cotizacion.Ejecutivo = new PersonaModel();
    cotizacion.Ejecutivo.Rut = this.rutEjecutivo;
    cotizacion.Ejecutivo.Dv = this.rutEjecutivoDv;
    cotizacion.Ejecutivo.Nombres = this.nombreEjecutivo;
    cotizacion.Ejecutivo.TipoRelacion = TipoRelacionPersonaEnums.EJECUTIVO;
    cotizacion.DiasVigencia = convenio.diasVigencia;

    cotizacion.ramos = convenio.ramos;

    this.llenarDatosFormulario(datosCotizacion);
    cotizacion.Vehiculo = this.llenarDatosVehiculo(datosCotizacion);
    cotizacion.FormaPago = this.llenarDatosPropuesta(datosCotizacion);

    return cotizacion;
  }

  procesarQueryParams(): Observable<Params> {
    return this.route.queryParams.pipe(
      tap(params => {
        this.cotizacionId = this.comunService.desencriptar(
          reemplazarTodo(params.a, ' ', '+'),
          environment.cryptoKeyContinuarCotizacion
        );
        this.rutEjecutivo = this.comunService.desencriptar(
          reemplazarTodo(params.r, ' ', '+'),
          environment.cryptoKeyContinuarCotizacion
        );
        this.rutEjecutivoDv = this.comunService.desencriptar(
          reemplazarTodo(params.d, ' ', '+'),
          environment.cryptoKeyContinuarCotizacion
        );
        this.nombreEjecutivo = this.comunService.desencriptar(
          reemplazarTodo(params.n, ' ', '+'),
          environment.cryptoKeyContinuarCotizacion
        );
        this.convenioId = parseInt(
          this.comunService.desencriptar(
            reemplazarTodo(params.c, ' ', '+'),
            environment.cryptoKeyContinuarCotizacion
          )
        );
      })
    );
  }

  private agregarVehiculoItemResumen(
    marca: string,
    modelo: string,
    anio: string
  ): void {
    const state = this.store.getState();
    const { datosVehiculo } = state.formulario.vehiculoForm;

    this.desplegableResumenService.agregarResumen({
      title: 'Datos del vehículo',
      expanded: true,
      items: [
        {
          title: datosVehiculo.patente ? 'Patente' : 'Modelo',
          description: datosVehiculo.patente
            ? datosVehiculo.patente.toUpperCase()
            : '',
          subDescription: `
          ${marca} 
          ${modelo} 
          ${anio}`,
        },
      ],
    });
  }

  private agregarAseguradoItemResumen(): void {
    const state = this.store.getState();
    const { datosPersonales } = state.formulario.aseguradoForm;

    this.desplegableResumenService.agregarResumen({
      title: 'Datos del asegurado',
      expanded: true,
      items: [
        {
          title: 'Rut',
          description: format(datosPersonales.rut.toString().replace('-', ''), {
            dots: true,
          }),
          subDescription: `${datosPersonales.nombres} ${datosPersonales.apellidos}`,
        },
      ],
    });
  }

  private initDesplegableResumen(): void {
    this.desplegableResumenService.init({
      nameCurrentProcess: 'Ingrese asegurado',
      resumen: [],
      numerocotizacion: '',
    });
  }

  llenarDatosFormulario(datosCotizacion: ContinuarCotizacion) {
    FormularioNuevo.aseguradoForm.datosPersonales.rut = format(
      datosCotizacion.rutAsegurado.replace('-', ''),
      { dots: true }
    );
    FormularioNuevo.aseguradoForm.datosContacto.correo =
      datosCotizacion.correoAsegurado;
    FormularioNuevo.aseguradoForm.datosContacto.celular =
      datosCotizacion.celularAsegurado;
    FormularioNuevo.aseguradoForm.datosContacto.telefono =
      datosCotizacion.telefonoAsegurado;
    FormularioNuevo.aseguradoForm.datosPersonales.nombres =
      datosCotizacion.nombresAsegurado;
    FormularioNuevo.aseguradoForm.datosPersonales.apellidos =
      datosCotizacion.apellidoAsegurado;
    FormularioNuevo.vehiculoForm.datosVehiculo.patente =
      datosCotizacion.patente;

    FormularioNuevo.vehiculoForm.datosVehiculo.marcaId = datosCotizacion.marca;
    FormularioNuevo.vehiculoForm.datosVehiculo.modeloId =
      datosCotizacion.modelo;

    FormularioNuevo.vehiculoForm.datosVehiculo.anioId = parseInt(datosCotizacion.anio);
    FormularioNuevo.vehiculoForm.datosVehiculo.usoId = datosCotizacion.uso;
    FormularioNuevo.vehiculoForm.datosVehiculo.estadoId =
      datosCotizacion.estado;

    FormularioNuevo.vehiculoForm.datosVehiculo.MarcaAs400 =
      datosCotizacion.marcaAs400;
    FormularioNuevo.vehiculoForm.datosVehiculo.ModeloAs400 =
      datosCotizacion.modeloAs400;

    FormularioNuevo.contratanteForm.datosPersonales.nombres =
      datosCotizacion.nombresContratante;
    FormularioNuevo.contratanteForm.datosPersonales.apellidos =
      datosCotizacion.apellidoContratante;
    FormularioNuevo.contratanteForm.datosPersonales.rut =
      datosCotizacion.rutContratante;
    FormularioNuevo.contratanteForm.datosContacto.correo =
      datosCotizacion.correoContratante;

    FormularioNuevo.contratanteForm.datosUbicacion.direccion =
      datosCotizacion.direccionContratante;
    FormularioNuevo.contratanteForm.datosUbicacion.comunaId =
      parseInt(datosCotizacion.comunaContratante);
    FormularioNuevo.contratanteForm.datosUbicacion.regionId =
      parseInt(datosCotizacion.regionContratante);

    FormularioNuevo.contratanteForm.detallesPago.formaPagoId =
      datosCotizacion.formaPago;
    FormularioNuevo.contratanteForm.detallesPago.numeroCuotasId =
      datosCotizacion.numeroCuotas;
    FormularioNuevo.contratanteForm.datosMotorChasis.numeroMotor =
      datosCotizacion.motor;
    FormularioNuevo.contratanteForm.datosMotorChasis.numeroChasis =
      datosCotizacion.chasis;
  }

  llenarDatosVehiculo(datosCotizacion: ContinuarCotizacion) {
    let vehiculo = new VehiculoModel();
    vehiculo.Anio = FormularioNuevo.vehiculoForm.datosVehiculo.anioId;
    vehiculo.Color = datosCotizacion.color;
    vehiculo.Marca = FormularioNuevo.vehiculoForm.datosVehiculo.marcaId;
    vehiculo.Modelo = FormularioNuevo.vehiculoForm.datosVehiculo.modeloId;
    vehiculo.MarcaAs400 = FormularioNuevo.vehiculoForm.datosVehiculo.MarcaAs400;
    vehiculo.ModeloAs400 =
      FormularioNuevo.vehiculoForm.datosVehiculo.ModeloAs400;
    vehiculo.Estado =
      FormularioNuevo.vehiculoForm.datosVehiculo.estadoId?.toString();
    vehiculo.Patente = FormularioNuevo.vehiculoForm.datosVehiculo.patente;
    vehiculo.Uso = FormularioNuevo.vehiculoForm.datosVehiculo.usoId?.toString();
    vehiculo.Estado = FormularioNuevo.vehiculoForm.datosVehiculo.estadoId;

    vehiculo.MarcaDesc = datosCotizacion.marcaDescripcion;
    vehiculo.ModeloDesc = datosCotizacion.modeloDescripcion;
    vehiculo.TipoVehiculo = datosCotizacion.tipoVehiculo;

    return vehiculo;
  }

  llenarDatosPropuesta(datosCotizacion: ContinuarCotizacion) {
    if (datosCotizacion?.numeroCuotas) {
      let formaPago = new FormaPagoModel();

      formaPago.CantidadCuotas = datosCotizacion?.numeroCuotas;
      formaPago.FormaPagoId = datosCotizacion?.formaPago;
      formaPago.TarifaCuota = datosCotizacion?.tarifaCuota;
      formaPago.TarifaCuotaUf = datosCotizacion?.tarifaCuotaUf;
      formaPago.MandatoId = datosCotizacion?.idMandato;

      return formaPago;
    }

    return undefined;
  }
}
