import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '@services/loader.service';
import { RouterService } from '@services/router.service';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/providers/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public background_set: any = { estado: false, url: '' };

  showLoader: boolean = false;
  textLoading$!: Observable<string>;

  constructor(
    private config: PrimeNGConfig,
    private shared: SharedService,
    private routerService: RouterService,
    private loaderService: LoaderService,
    private cd: ChangeDetectorRef
  ) {
    this.background_set = this.shared.bg_data;
  }

  ngOnInit(): void {
    this.listenLoader();
    this.initRouteConfigWithLoading();

    this.config.setTranslation({
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
    });
  }

  private listenLoader(): void {
    this.textLoading$ = this.loaderService.textLoading$;
    this.loaderService.status$.subscribe((status) => {
      this.showLoader = status;
      this.cd.detectChanges();
    });
  }

  private initRouteConfigWithLoading(): void {
    this.routerService.initRouteConfigWithLoading();
  }
}
