import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EstadoCotizacionEnum,
  SubEstadoCotizacionEnum,
  TipoArchivoEnum,
} from '@enums/estado-cotizacion.enum';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { CotizacionService } from '@services/cotizacion.service';
import { PageContentTitleService } from '@services/page-content-title.service';
import { PropuestaService } from '@services/propuesta.service';
import { StepService } from '@services/step.service';
import { Store } from '@services/store.service';

@Component({
  selector: 'app-exito',
  templateUrl: './exito.component.html',
  styleUrls: ['./exito.component.css'],
})
export class ExitoComponent implements OnInit, OnDestroy {
  descargandoPropuesta = false;
  descargandoCotizacion = false;
  correo: string = '';
  nombreCliente: string = '';
  TarifaCuotaUf?: string = '';
  TarifaCuotaPeso?: string = '';
  cotizacionId: string = '';
  IdCot: number = 0;
  urlBase: string = '';

  constructor(
    private store: Store<SeguroStore>,
    private cotizacionService: CotizacionService,
    private pageContentTitleService: PageContentTitleService,
    private stepService: StepService,
    private propuestaService: PropuestaService
  ) {}

  ngOnInit(): void {
    let state = this.store.getState();
    let objeto = JSON.parse(JSON.stringify(state));
    this.cotizacionId = objeto.datosCotizacion.IdCotizacion;
    this.IdCot = objeto.datosCotizacion.id;
    this.urlBase = objeto.urlBase;
    this.correo = objeto.formulario.aseguradoForm.datosContacto.correo;
    this.nombreCliente =
      objeto.formulario.aseguradoForm.datosPersonales.nombres +
      ' ' +
      objeto.formulario.aseguradoForm.datosPersonales.apellidos;
    this.TarifaCuotaUf =
      objeto.datosCotizacion.FormaPago?.TarifaCuotaUf?.toString() ?? '';
    this.TarifaCuotaPeso =
      objeto.datosCotizacion.FormaPago?.TarifaCuota?.toString() ?? '';
    this.configurarStepYTitulo();
    this.finalizar();
  }

  private configurarStepYTitulo(): void {
    this.stepService.markAllAsCompletedUntil(5);
    this.stepService.markStepAsActive(5);
    this.pageContentTitleService.setContentTitle(
      'Link de pago enviado',
      'El link de pago fue enviado.'
    );

    this.pageContentTitleService.setContentTitle(
      'Link de pago enviado',
      'El link de pago fue enviado'
    );
  }

  ngOnDestroy(): void {}

  cotizarOtroSeguro(): void {
    window.location.href = this.urlBase;
  }

  async descargarPropuesta() {
    this.descargandoPropuesta = true;

    let blob = await this.cotizacionService.obtenerDocumentoCotizacion(
      this.cotizacionId,
      TipoArchivoEnum.PROPUESTA
    );
    const downloadURL = URL.createObjectURL(blob);

    const idPropuesta =
      await this.propuestaService.obtenerIdPropuestaPorIdCotizacion(
        this.cotizacionId
      );

    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = `Propuesta ${idPropuesta}.pdf`;
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(downloadURL);
      this.descargandoPropuesta = false;
    }, 2000);
  }

  finalizar(): void {
    let state = this.store.getState();
    let datosCotizacion = state.datosCotizacion;
    datosCotizacion.EstadoCotizacion = EstadoCotizacionEnum.PROPUESTA;
    datosCotizacion.SubEstadoCotizacion =
      SubEstadoCotizacionEnum.ENVIO_LINK_PAGO;

    this.store.setState({
      ...state,
      datosCotizacion: datosCotizacion,
    });
  }

  async descargarCotizacion() {
    this.descargandoCotizacion = true;

    let blob = await this.cotizacionService.obtenerDocumentoCotizacion(
      this.cotizacionId,
      TipoArchivoEnum.COTIZACION
    );
    const downloadURL = URL.createObjectURL(blob);
    const state = this.store.getState();

    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = `Cotizacion ${state.datosCotizacion.id}.pdf`;
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(downloadURL);
      this.descargandoCotizacion = false;
    }, 2000);

    setTimeout(() => {
      this.descargandoCotizacion = false;
    }, 2000);
  }
}
