import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { encriptarParametrosUrl } from '@utils/encript.util';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  redirectError(mensajeError: string = 'Inténtelo más tarde') {
    const url = `/error?t=${encriptarParametrosUrl('Error')}&m=${encriptarParametrosUrl(mensajeError)}`;
    this.router.navigateByUrl(url);
  }
}
