<header class="bs-header" *ngIf="tipo" [ngClass]="{ simple: tipo == 'simple' }">
  <div class="bs-wrapper bs-contenedor">
    <a class="logotipo" href="https://www.bciseguros.cl" target="_blank">
      <figure>
        <img
          src="/assets/img/svg/logo_bciseguros.svg"
          alt="Logo de BCI SEGUROS"
        />
      </figure>
    </a>
  </div>
</header>
<div class="bs-header-span"></div>
