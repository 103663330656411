import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GpsService {
  constructor(private httpClient: HttpClient) {}

  async obtenerDatosGps(cotizacionId: any): Promise<any> {
    let params = new HttpParams().set('parametro', cotizacionId.toString());

    return this.httpClient
      .get<any>('/Gps/ObtenerGpsPorCotizacionId', { params })
      .toPromise();
  }
}
