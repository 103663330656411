import { HttpErrorResponse } from '@angular/common/http';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { VehiculoModel } from '@model/vehiculo-model';
import { DialogService } from '@services/dialog.service';
import { PatenteService } from '@services/patente.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class PatenteValidator {
  static patenteChilenaValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    if (control.value === null || control.value == '') {
      return null;
    }

    const patenteRegex =
      /^[A-HJ-PR-Z]{2}([A-HJ-PR-Z]{2}\d{2}|\d{4}|\d{3}|[A-HJ-PR-Z]{1}\d{2})$/i;

    const esValida = patenteRegex.test(control.value);
    return esValida ? null : { invalidPattern: 'Patente inválida' };
  }

  static consultarPatenteNuevoAsync(
    vehiculosService: PatenteService,
    dialogService: DialogService
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return vehiculosService
        .validaFechaPatenteNuevo(
          new VehiculoModel({
            patente: control.value.toUpperCase(),
          })
        )
        .pipe(
          map((fechaPatente) => {
            if (!fechaPatente.estado) {
              control.parent!.get('patente')?.setValue('');
              control
                .parent!.get('patente')
                ?.setErrors({ invalidPattern: fechaPatente.descripcion });
            }
            return null;
          }),
          catchError((e: HttpErrorResponse) => {
            e.error?.data.mensaje ===
              'El token no es válido o el usuario no es humano.' &&
              dialogService.showError(
                'Error',
                'El token no es válido o el usuario no es humano.'
              );
            return of({ serverError: e.error?.data });
          })
        );
    };
  }
}
