import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CotizacionVentaAsistida,
  CotizacionVentaAsistidaResponse,
} from '@interfaces/cotizacion-venta-asistida.interface';
import { ContinuarCotizacion } from '@model/continuar-cotizacion.model';
import { Observable } from 'rxjs';
import { ScriptEjecutivoResponse } from '@interfaces/response/script-ejecutivo-response.interface';
import { RequestCambiarEstadoCotizacion } from '@interfaces/request/cambiar-estado-cotizacion-request.interface';

@Injectable({
  providedIn: 'root',
})
export class CotizacionService {
  constructor(private httpClient: HttpClient) {}

  async subirFactura(
    archivo: any,
    tipoArchivo: number,
    cotizacionId: string
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', archivo);
    const url =
      '/cotizacion/Upload?tipoArchivo=' +
      tipoArchivo +
      '&cotizacionId=' +
      cotizacionId;

    let resultado = this.httpClient.put(url, formData).toPromise();

    return resultado;
  }

  async generarSolicitudInspeccion(cotizacionId: string): Promise<any> {
    return this.httpClient
      .post('/cotizacion/generar-solicitud-inspeccion', {
        IdCotizacion: cotizacionId,
      })
      .toPromise();
  }

  async generarDocumentoCotizacion(
    cotizacionId: string,
    descuento: number,
    deducibles: any[],
    nombreCorredor: string,
    rutCorredor: string
  ): Promise<any> {
    const body = {
      CotizacionId: cotizacionId,
      DeduciblesId: deducibles,
      Descuento: descuento,
      NombreCorredor: nombreCorredor,
      RutCorredor: rutCorredor,
    };
    return this.httpClient
      .post('/cotizacion/GenerarDocumentocotizacion', body, {
        responseType: 'blob',
      })
      .toPromise();
  }

  async obtenerDocumentoCotizacion(
    cotizacionId: string,
    tipoDocumento: number
  ): Promise<any> {
    const url = '\\ObtenerDocumentoCotizacion';
    let params = new HttpParams()
      .set('id', cotizacionId)
      .set('ta', tipoDocumento.toString());

    return this.httpClient
      .get('/cotizacion' + url, { responseType: 'blob', params })
      .toPromise();
  }

  obtenerCotizacion(cotizacionId: string): Observable<ContinuarCotizacion> {
    const url = '\\ObtenerDatosContinuarCotizacion';
    let params = new HttpParams().set('id', cotizacionId);
    return this.httpClient.get<ContinuarCotizacion>('/cotizacion' + url, {
      params,
    });
  }

  async consultarEstadoInspeccion(numeroInspeccion: string) {
    const url = '\\consultar-estado-inspeccion';
    let params = new HttpParams().set('numeroInspeccion', numeroInspeccion);
    return this.httpClient.get('/cotizacion' + url, { params }).toPromise();
  }

  async obtenerScriptEjecutivoPromise(
    cotizacionId: string
  ): Promise<{ scriptEjecutivo: string | null }> {
    const url = '\\ObtenerScriptEjecutivo';
    let params = new HttpParams().set('cotizacionId', cotizacionId);
    return this.httpClient
      .get<{ scriptEjecutivo: string | null }>('/cotizacion' + url, { params })
      .toPromise();
  }

  async obtenerNumeroInspeccion(cotizacionId: string) {
    const url = '\\obtener-numero-inspeccion';
    let params = new HttpParams().set('cotizacionId', cotizacionId);
    return this.httpClient.get('/cotizacion' + url, { params }).toPromise();
  }

  async guardarAseguradoYEjecutivoVentaAsistida(
    data: CotizacionVentaAsistida
  ): Promise<CotizacionVentaAsistidaResponse> {
    return this.httpClient
      .post<CotizacionVentaAsistidaResponse>(
        '/cotizacion/GuardarAseguradoYEjecutivoVentaAsistida',
        data
      )
      .toPromise();
  }

  async guardarVehiculoVentaAsistida(data: any): Promise<any> {
    return this.httpClient
      .post('/cotizacion/GuardarVehiculoVentaAsistida', data)
      .toPromise();
  }

  async seleccionarPlanVentaAsistida(data: any): Promise<any> {
    return this.httpClient
      .post('/cotizacion/SeleccionarPlanVentaAsistida', data)
      .toPromise();
  }

  async guardarContratanteVentaAsistida(data: any): Promise<any> {
    return this.httpClient
      .post('/cotizacion/GuardarContratanteVentaAsistida', data)
      .toPromise();
  }

  asignarProductosEspeciales(data: any) {
    return this.httpClient.post(
      '/cotizacion/asignar-productos-especiales',
      data
    );
  }

  obtenerScriptEjecutivo(idCotizacion: string): Observable<ScriptEjecutivoResponse> {
    const params = new HttpParams().set('idCotizacion', idCotizacion);
    return this.httpClient.get<ScriptEjecutivoResponse>('/cotizacion/ObtenerScriptEjecutivo', { params });
  }


  async cambiarEstadoCotizacion(data: RequestCambiarEstadoCotizacion): Promise<any> {
    return this.httpClient.post('/cotizacion/CambiarEstadoCotizacion', data).toPromise();
  }
}
