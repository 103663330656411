import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { DialogService } from '@services/dialog.service';
import { LoaderService } from '@services/loader.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  private readonly MAX_RETRIES = 1;

  constructor(
    private dialogService: DialogService,
    private loaderService: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((error, index) => {
            if (error?.name === 'TimeoutError' && index < this.MAX_RETRIES) {
              this.logRetryAttempt(index, req);
              return timer(0);
            }
            return throwError(error);
          })
        )
      ),
      catchError((error) => {
        if (error?.name === 'TimeoutError') {
          this.loaderService.hide();
          this.showDialog();
        }
        return throwError(error);
      })
    );
  }

  private showDialog(): void {
    this.dialogService.openDialog({
      textHeader: 'Ups!',
      textContent: 'Por favor reintente nuevamente',
      showBtnOk: false,
      textHandleOk: 'Aceptar',
      dismisable: true,
      showClose: true,
      tipoMensaje: 1,
    });
  }

  private logRetryAttempt(index: number, req: HttpRequest<any>) {
    console.log(
      `%c🔄 Reintentando solicitud...`,
      'font-weight: bold; color: orange;'
    );
    console.log(
      `%cMétodo: %c${req.method}`,
      'font-weight: bold;',
      'font-weight: normal;'
    );
    console.log(
      `%cURL: %c${req.url}`,
      'font-weight: bold;',
      'font-weight: normal;'
    );
    console.log(
      `%cReintento número: %c${index + 1}`,
      'font-weight: bold;',
      'font-weight: normal;'
    );
  }
}
