import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolizaVigenteResponse } from '@interfaces/poliza-vigente-response.interface';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PolizaLeasing, SeguroStore } from '@interfaces/seguro-store.interface';
import { Store } from '@services/store.service';

@Injectable({
  providedIn: 'root',
})
export class PolizaService {
  constructor(
      private httpClient: HttpClient,
      private store: Store<SeguroStore>,
  ) {}

  public async generarPoliza(parametro: string): Promise<any> {
    return this.httpClient
      .post<any>('/Poliza/EmisionSuscripcion', { parametro })
      .toPromise();
  }

  public async generarDirectaPoliza(numeroPoliza: string): Promise<any> {
    return this.httpClient
      .post('/Poliza/EmisionDirecta', { parametro: numeroPoliza })
      .toPromise();
  }

  public async generarMandatoPoliza(
    cotizacionId: string,
    idMandato?: string,
    rutContratante?: string
  ): Promise<any> {
    return this.httpClient
      .post('/Poliza/EmisionMandato', {
        cotizacionId,
        idMandato,
        rutContratante,
      })
      .toPromise();
  }

  public async obtenerArchivoPoliza(numeroPoliza: string): Promise<any> {
    return this.httpClient
      .post(
        '/Poliza/ObtenerPdf',
        { parametro: numeroPoliza.toString() },
        { responseType: 'blob' }
      )
      .toPromise();
  }

  public obtenerPolizaVigente(nroPolizaLeasing: string): Observable<PolizaVigenteResponse> {
    const params = new HttpParams().set('idPoliza', nroPolizaLeasing);
    return this.httpClient.get<PolizaVigenteResponse>('/Poliza/ObtenerPolizaVigente', { params })
      .pipe(
        tap(resp => {
          const state = this.store.getState();
          let polizaLeasing: PolizaLeasing;
          if (resp.encontrado === 'NOK') {
            polizaLeasing = { esValida: false, motivo: resp.mensaje, fechaFinVigencia: null, idAseguradora: null };
            this.store.setState({ ...state, polizaLeasing });
            throw new Error(resp.mensaje || 'No se encontró póliza con el número ingresado.');
          }
          polizaLeasing = { esValida: true, motivo: null, fechaFinVigencia: resp.fechaFinVigencia, idAseguradora: resp.idAseguradora, nroPoliza: nroPolizaLeasing };
          this.store.setState({ ...state, polizaLeasing });
        }),
        catchError(error => {
          return throwError(error);
        }),

      );
  }
}
